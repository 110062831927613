<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Saldo</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.saldo"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  :allow-empty="false"
                  :class="{ 'p-invalid': v$.form.saldo.$invalid && submitted }"
                  @focus="selectAllText('item')"
                />
                <small
                  v-if="
                    (v$.form.saldo.$invalid && submitted) ||
                    v$.form.saldo.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.saldo.required.$message }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        saldo: {
          required: helpers.withMessage('Saldo harus diisi.', required),
        },
      },
    }
  },
}
</script>

