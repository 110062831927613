<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Neraca Saldo</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Data Neraca Saldo</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-10 lg:col-offset-1">
        <div class="card bg-white">
          <DataTable
            :value="this.items"
            :row-hover="true"
            :loading="isLoading"
            :paginator="true"
            :rows="10"
            :lazy="true"
            :totalRecords="this.totalRecords"
            :rowsPerPageOptions="[10, 25, 50]"
            filterDisplay="row"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
            class="p-datatable-sm"
            @page="onPage($event)"
            @sort="onSort($event)"
            @filter="onFilter($event)"
          >
            <template #empty>
              <div class="text-center">Tidak ada data.</div>
            </template>
            <template #loading>
              <div class="text-center">Memuat data, harap tunggu...</div>
            </template>
            <Column
              field="coa_item.kode"
              header="Kode"
              filterField="coa_item_kode"
              style="min-width: 50px"
              :showFilterMenu="false"
            >
            </Column>
            <Column
              field="coa_item.nama"
              header="Perkiraan"
              filterField="coa_item_nama"
              style="min-width: 250px"
              :showFilterMenu="false"
            >
            </Column>
            <Column
              field="saldo"
              header="saldo"
              style="text-align: right; justify-content: flex-end"
              :showFilterMenu="false"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.saldo || 0) }}
              </template>
            </Column>
            <Column style="text-align: right; justify-content: flex-end">
              <template #body="{ data }">
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  class="
                    p-button-outlined p-button-secondary p-button-text
                    mr-2
                  "
                  @click="onEditData(data)"
                />
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column footer="Total Debit:" :colspan="2" />
                <Column
                  :footer="formatCurrency(this.totalDebit)"
                  style="text-align: right; justify-content: flex-end"
                />
              </Row>
              <Row>
                <Column footer="Total Kredit:" :colspan="2" />
                <Column
                  :footer="formatCurrency(this.totalKredit)"
                  style="text-align: right; justify-content: flex-end"
                />
              </Row>
              <Row>
                <Column footer="Selisih:" :colspan="2" />
                <Column
                  :footer="formatCurrency(this.totalSelisih)"
                  style="text-align: right; justify-content: flex-end"
                />
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>
      </div>
    </div>
    <Dialog
      :header="'Edit item'"
      v-model:visible="dialog"
      class="p-overflow-hidden"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      modal
      maximizable
    >
      <form-neraca-saldo
        :item="form"
        :loading="isLoadingSave"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import NeracaSaldoService from '@/services/NeracaSaldoService'
import FormNeracaSaldo from '@/components/keuangan/FormNeracaSaldo'
import errorHandler from '@/helpers/error-handler'
import { formatCurrency } from '@/helpers/index'
import { FilterMatchMode } from 'primevue/api'
import Hotkey from '@/components/Hotkey'

export default {
  name: 'Neraca Saldo',
  components: {
    FormNeracaSaldo,
    Hotkey,
  },
  data() {
    return {
      neracaSaldoService: null,
      dialog: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      form: {},
      totalRecords: 0,
      totalDebit: 0,
      totalKredit: 0,
      totalSelisih: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'coa_item_id',
        sortOrder: 'asc',
        filters: null,
      },
      filters: {
        coa_item_kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        coa_item_nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  created() {
    this.neracaSaldoService = new NeracaSaldoService()
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val)
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.rowFilters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.rowFilters
      this.options.page = 1
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.neracaSaldoService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.records.data
          this.totalDebit = res.data.summary.saldo_debit
          this.totalKredit = res.data.summary.saldo_kredit
          this.totalSelisih = res.data.summary.selisih
          this.totalRecords = res.data.records.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Neraca Saldo', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.form = { ...this.form }
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      this.neracaSaldoService
        .update(data.id, data)
        .then((res) => {
          if (res.data.status === 200) {
            this.loadData()
            this.$toast.add({
              severity: 'success',
              summary: 'Data Neraca Saldo',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.dialog = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Neraca Saldo', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
